















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { listMixin } from "@/mixins/listMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import { PostSearch } from "@/request/storehouse";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private source: any = "";
  private data: any = {};
  private list: any = [];
  private kuChange(e: any) {
    this.$forceUpdate();
  }
  private goSearch() {
    this.filters.tab = "";
    this.filters.current_page = 1;
    this.getList();
  }
  private get kuList() {
    return this.$store.state.kuList;
  }
  private changeKu(val: any) {
    // this.$store.commit("updateCurrentKu", val);
    // this.filters.type = "全部";
    // this.filters.current_page = 1;
    // this.getList();
  }
  private goDetail(item: any) {
    if (item.kind == "古籍") {
      this.$router.push({
        path: "/main/storehouse/guji-read",
        query: {
          id: item.id,
          title: item.name.split("《")[1].split("》")[0],
          page: "search",
          source: this.source,
        },
      });
    } else {
      this.$store.commit("updateIfCompose", false);
      this.$router.push({
        path: "/main/check/bentijiaoyan/compose",
        query: {
          id: item.id,
          kind: item.kind,
          source: this.source + " / " + item.kind + " / " + item.name,
        },
      });
    }
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    if (!this.filters.search) {
      this.$message.warning("请输入搜索关键词");
      return;
    }
    const params: any = {
      search: this.filters.search,
      table_name: this.filters.ku,
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
    };

    if (params.table_name == "全部") {
      params.table_name = "";
    }
    localStorage.setItem("searchFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostSearch(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data;
        this.list = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.list.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  mounted() {
    this.filters.tab = "全部";
    const d = localStorage.getItem("searchFilter") || "";
    if (this.$route.query.data) {
      const query: any = JSON.parse(this.$route.query.data as any);
      this.filters.search = query.searchText;
      this.filters.ku = this.$store.state.currentKu;
      this.source = query.source;
      localStorage.setItem("searchFilter", JSON.stringify(this.filters));
    }
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
